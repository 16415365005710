/* eslint-disable @typescript-eslint/indent */
import { Slider, Typography } from '@pwskills/rachnaui';
// import Link from 'next/link';
import React from 'react';
import { classNames, randomId } from '@pwskills/rachnaui/utils';
import useRedirectToCategory from '@/hooks/useRedirectionToCategory';
import { useRouter } from 'next/router';
import ViewAllButton from '../common/ViewAllButton';
import LoadingFeatureCardSkeleton from '../../common/LoadingSkeleton/LoadingFeatureCardSkeleton';
import CoursesCard from '../../common/CoursesCard';
import { ICourses } from '../../../interfaces/homePage';
import { domainUrl } from '../../../utils/common/env.constants';

const CategorySection = ({
  categoryCourses,
  isLoading,
  title,
  slug,
  categoryId,
}: {
  categoryCourses: Array<ICourses> | undefined;
  isLoading: boolean;
  title: string;
  slug: string;
  categoryId?: string;
}) => {
  const { goToCategoryPage } = useRedirectToCategory();
  const router = useRouter();
  const handleClick = () => {
    goToCategoryPage(
      slug,
      router?.asPath?.split('/')[1]
        ? `${
            router?.asPath?.split('/')[1]
          }&position=homepage_explore_categories&source=${domainUrl}&page_ref=home_page`
        : `?position=homepage_explore_categories&source=${domainUrl}&page_ref=home_page`
    );
  };
  return (
    <div>
      <div className="flex gap-3 justify-between items-center !mt-8 medium:!mt-6">
        <Typography
          variant="semi-bold"
          component="h4"
          className="!text-[#3D3D3D] medium:!text-[#E97862] font-inter !text-[18px] medium:!text-[20px]"
        >
          {title}
        </Typography>
        <div className="flex justify-center">
          {/* <Link href={`/category/${slug}?position=homepage_offerings&source=${domainUrl}`}> */}
          <button type="button" onClick={handleClick}>
            <ViewAllButton
              classNames="inline-flex bg-white shadow-medium hover:bg-white py-3 !py-2 !px-5 text-gray-850 medium:!font-bold whitespace-nowrap"
              clickText="category_explore"
              section={title}
            />
          </button>

          {/* </Link> */}
        </div>
      </div>

      <div id="sliders" className="pt-6 medium:!pt-4 large:!pt-6 border-b-[1px] border-[#EFEFEF]">
        <Slider
          className={classNames(
            '!gap-2 medium:!gap-x-[22px] [&>div]:mr-2 [&>*:last-child]:mr-0 !mx-auto [&>div]:large:ml-4 !pt-4 !pl-2'
          )}
          positionOfSliderInMobile="bottom"
        >
          {isLoading
            ? Array(6)
                .fill(randomId())
                .map(() => <LoadingFeatureCardSkeleton />)
            : categoryCourses &&
              categoryCourses.map((categoryCourse: ICourses) => (
                <CoursesCard
                  course={categoryCourse}
                  key={categoryCourse._id}
                  pageFrom="home_page"
                  section={title}
                  position="our_courses"
                  categoryName={title}
                  categoryId={categoryId}
                />
              ))}
        </Slider>
      </div>
    </div>
  );
};

export default CategorySection;
