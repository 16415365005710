/* eslint-disable @typescript-eslint/indent */
import { Button, Container, Slider, Typography } from '@pwskills/rachnaui';
import { classNames, randomId } from '@pwskills/rachnaui/utils';
import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import { IError } from '@/interfaces/common';
import HomePage from '@/api/homePage';
import ViewAllButton from '../common/ViewAllButton';
// import { ICourses } from '../../../interfaces/homePage';
import LoadingFeatureCardSkeleton from '../../common/LoadingSkeleton/LoadingFeatureCardSkeleton';
import CoursesCard from '../../common/CoursesCard';
import HomepageHeaders from '../../common/HomepageHeader';
import { ICourses, IHomePageFilters } from '../../../interfaces/homePage';
import { dataLayer } from '../../../utils/common/dataLayer.utils';

const EssentialSkills = ({
  loading,
  filterTiles,
  freeCoursesList,
}: {
  loading: boolean;
  filterTiles: IHomePageFilters[];
  freeCoursesList: ICourses[];
}) => {
  const [initialCourseLength, setInitialCourseLength] = useState(freeCoursesList.length);
  const [filteredCourses, setFilteredCourses] = useState<ICourses[]>(freeCoursesList);

  const [activeFilter, setActiveFilter] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(loading);

  const sendDataLayerEvent = (id: string) => {
    dataLayer({
      event: 'filter_used',
      filter_used: [
        {
          categories: id,
        },
      ],
      section_name: 'homepage_free_courses',
      filter_type: 'tag',
    });
  };

  const fetchFilteredCourses = async (id: string | null) => {
    if (id) {
      sendDataLayerEvent(id);
    }
    setIsLoading(true);
    try {
      const payload = id ? { freeCourseFilter: id } : {};

      const response = await HomePage.getHomePageData(payload);
      if (response) {
        setFilteredCourses((response as { data: { freeCourses: ICourses[] } }).data?.freeCourses);
      }
    } catch (error: unknown) {
      // eslint-disable-next-line no-console
      console.error((error as IError)?.error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setFilteredCourses(freeCoursesList);
    setInitialCourseLength(freeCoursesList.length);
    setIsLoading(loading);
  }, [freeCoursesList]);

  return (
    <div>
      {initialCourseLength > 0 && (
        <section className="py-8 large:!py-14 bg-[#F8FAFB]">
          <Container>
            <header className="flex items-start justify-between">
              <HomepageHeaders title="Your Launchpad to Learning" />

              <Link href="/courses/?pricing=true&source=free_view_more">
                <ViewAllButton
                  classNames="hidden medium:!inline-flex whitespace-nowrap bg-white shadow-medium hover:bg-white !py-2 !px-5 text-gray-850 !font-bold"
                  clickText="view_all"
                  section="homepage_free_courses"
                />
              </Link>
            </header>
            <div className="mt-2 medium:!mt-3 flex justify-between items-center">
              <Typography
                variant="regular"
                component="body-regular"
                className="!text-[#3D3D3D] medium:!text-[#757575] large:!text-[#1B2124]"
              >
                Essential Skills, Zero Cost
              </Typography>
              <Link href="/courses/?pricing=true">
                <ViewAllButton
                  classNames="inline-flex medium:hidden whitespace-nowrap bg-white shadow-medium hover:bg-white !py-2 !px-5 text-gray-850 !font-bold"
                  clickText="view_all"
                  section="homepage_free_courses"
                />
              </Link>
            </div>

            <div
              className="flex flex-row gap-x-6 overflow-x-auto !mt-8 medium:!mt-4 large:!mt-14"
              style={{ scrollbarWidth: 'none' }}
            >
              {filterTiles.map((item) => {
                if (item.coursesCount > 0) {
                  return (
                    <Button
                      variant="secondary"
                      className={`hover:bg-[#EAECEF] !w-auto !px-4 whitespace-nowrap min-w-fit !text-gray-850 bg-white font-extrabold large:!font-bold rounded-lg !text-[14px] !border !border-[#EFEFEF] ${
                        activeFilter === item?.categoryDetails?.title
                          ? 'bg-[#FFF0ED] !border-[#fc8e78] hover:!bg-[#FFF0ED]'
                          : 'bg-white !border-[#EFEFEF]'
                      }`}
                      onClick={() => {
                        const newFilter =
                          activeFilter === item?.categoryDetails?.title
                            ? null
                            : item?.categoryDetails?.title;
                        setActiveFilter(newFilter);
                        fetchFilteredCourses(newFilter ? item?.categoryDetails?._id : null);
                      }}
                    >
                      <Typography variant="bold" component="body-small" className="min-w-[160px]">
                        {item?.categoryDetails?.title}
                      </Typography>
                    </Button>
                  );
                }
                return null;
              })}
            </div>

            <div id="sliders" className="pt-8 medium:!pt-6">
              <Slider
                className={classNames(
                  '!gap-2 medium:!gap-x-[22px] [&>div]:mr-2 [&>*:last-child]:mr-0 !mx-auto [&>div]:large:ml-4 !pt-4 !pl-2'
                )}
                positionOfSliderInMobile="bottom"
              >
                {isLoading ? (
                  Array(6)
                    .fill(randomId())
                    .map(() => <LoadingFeatureCardSkeleton />)
                ) : filteredCourses && filteredCourses.length > 0 ? (
                  filteredCourses.map((freeCourse) => (
                    <CoursesCard
                      course={freeCourse}
                      key={freeCourse._id}
                      pageFrom="home_page"
                      section="homepage_free_courses"
                      position="home_free_offerings"
                    />
                  ))
                ) : (
                  <div className="flex flex-col items-center justify-center w-full">
                    <img
                      src="/images/courseListing/noCourseFound.gif"
                      alt="no-course-found"
                      className="w-[320px] h-[320px]"
                      width={320}
                      height={320}
                    />
                    <Typography
                      variant="semi-bold"
                      component="body-regular"
                      className="text-center text-[16px] text-[#3D3D3D]"
                    >
                      No Course Found
                    </Typography>
                  </div>
                )}
              </Slider>
            </div>
          </Container>
        </section>
      )}
    </div>
  );
};

export default EssentialSkills;
