/* eslint-disable @typescript-eslint/indent */
import { Card, Typography } from '@pwskills/rachnaui';
import { IExplore } from '@/interfaces/homePage';
import React, { useState } from 'react';
import useRedirectToCategory from '@/hooks/useRedirectionToCategory';
import ArrowRight from '../../common/Icons/ArrowRight';
import { getRandomId } from '../../common/video/utils';
import { dataLayer } from '../../../utils/common/dataLayer.utils';
import { domainUrl } from '../../../utils/common/env.constants';

const CategoryCard = (category: IExplore) => {
  const [isHovered, setIsHovered] = useState(false);
  const { goToCategoryPage } = useRedirectToCategory();
  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const sendDataLayerEvent = (type?: string, section?: string) => {
    dataLayer({
      event: 'clicks',
      click_text: type,
      section,
    });
  };

  return (
    <Card
      key={getRandomId()}
      className="flex w-[93%] medium:!w-[332px] large:!w-[31%] items-center justify-start gap-x-4 !m-0 large:!self-start !bg-[#F8FAFB] !p-0 shadow-light hover:large:!shadow-medium cursor-pointer"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{
        background: isHovered ? 'linear-gradient(90deg, #EFFAFF 0%, #F7E0FF 100%)' : 'none',
      }}
      onClick={() => {
        sendDataLayerEvent('category explore', 'explore_categories_homepage');
        goToCategoryPage(
          category?.categoryDetails[0].slug,
          `?position=homepage_explore_categories&source=${domainUrl}&page_ref=home_page`
        );
      }}
    >
      <div className="flex flex-col w-full h-full justify-between">
        {/* 1st component */}
        <div className="flex flex-row px-3 py-2 large:!pt-5 large:!px-4 large:!pb-4 gap-[20px] large:gap-[18px] justify-between items-center">
          <div className="h-full gap-[6px] flex flex-col">
            <Typography
              component="h4"
              variant="semi-bold"
              className="text-gray-850  !text-[16px]  !leading-[24px] large:!text-[20px] large:!leading-[30px] !max-w-full !line-clamp-1"
            >
              {category?.categoryDetails[0].title}
            </Typography>
            <Typography
              component="body-regular"
              variant="regular"
              className="text-gray-650 !line-clamp-2 !text-[12px] large:!text-[14px] medium:!leading-[18px] large:!leading-[22px] !h-[36px] large:!h-[44px] w-[90%]"
            >
              {category?.categoryDetails[0].subText}
            </Typography>
          </div>

          <div className="flex items-center !w-[56px] !h-[56px]  !min-w-[56px] !min-h-[56px] large:!w-[72px] large:!h-[72px] large:!min-w-[72px] large:!min-h-[72px]">
            <img
              src={category?.categoryDetails[0].icon}
              alt="category-icon"
              className="w-full h-full"
              width={56}
              height={56}
            />
          </div>
        </div>

        {/* 2nd component */}
        <div className="flex items-center justify-between bg-white !px-4 !py-[13px] large:!py-[17px] rounded-b-lg">
          <Typography
            component="body-tiny"
            variant="semi-bold"
            className={`${
              isHovered ? 'text-[#3D3D3D]' : 'text-[#757575]'
            } !text-[12px] !leading-[18px]`}
          >
            Know More
          </Typography>
          <div className="cursor-pointer hidden large:!block">
            <ArrowRight isHovered={isHovered} />
          </div>
          <div className="cursor-pointer  large:!hidden">
            <ArrowRight isHovered size={20} />
          </div>
        </div>
      </div>
    </Card>
  );
};

export default CategoryCard;
