import { Button, Card, Typography } from '@pwskills/rachnaui';
import { ArrowForward } from '@pwskills/rachnaui/Icons';
import { classNames } from '@pwskills/rachnaui/utils';
import Link from 'next/link';
import React from 'react';

type Props = {
  product: {
    id: number;
    imgLink: string;
    title: string;
    description: string;
    link: string;
    hoverBg: string;
    bg: string;
  };
  onClick: (value: string) => void;
};

const ProductCard = ({ product, onClick }: Props) => {
  return (
    <Card
      key={product.id}
      className={classNames(
        'px-8 large:!px-7 !py-8 large:!py-7 flex flex-col items-start w-full regular:!w-[360px] large:!w-full xlarge:!w-96 gap-y-4 group !shadow-light large:!shadow-medium transition-all ease-in-out duration-300 !text-center regular:!text-left',
        product?.hoverBg,
        product?.bg,
        'large:bg-white'
      )}
      onClick={() => onClick(product.title)}
    >
      <figure className="w-full">
        <img src={product.imgLink} alt="product-icon" className="mx-auto regular:!mx-0" />
      </figure>

      <Typography variant="bold" component="h4" className="text-center regular:!text-left w-full">
        {product.title}
      </Typography>
      <Typography variant="light" component="subheading" className="text-gray-650">
        {product.description}
      </Typography>
      <Link
        href={{
          pathname: `${product.link}`,
          query: {
            source: window.location.href,
            position: 'homepage_products',
          },
        }}
        className="w-full"
        passHref
      >
        <Button
          variant="primary"
          size="small"
          endIcon={<ArrowForward />}
          className="mx-auto regular:!mx-0 large:!opacity-0 bg-gray-900 mt-1 transition-all ease-in-out duration-300 group-hover:!opacity-100 hover:bg-gray-900 rounded"
        >
          Explore
        </Button>
      </Link>
    </Card>
  );
};

export default ProductCard;
