import React from 'react';

const ArrowRight = ({ isHovered, size = 26 }: { isHovered: boolean; size?: number }) => {
  if (size === 20) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M20 10C20 4.4774 15.5226 0 10 0C4.4774 0 0 4.4774 0 10C0 15.5226 4.4774 20 10 20C15.5226 20 20 15.5226 20 10ZM9.77644 14.3923C9.70471 14.3212 9.64769 14.2366 9.60865 14.1434C9.56961 14.0502 9.54931 13.9502 9.54891 13.8492C9.54851 13.7482 9.56802 13.6481 9.60631 13.5546C9.64461 13.4611 9.70095 13.376 9.77212 13.3043L12.2875 10.7692H5.86538C5.66137 10.7692 5.46572 10.6882 5.32146 10.5439C5.1772 10.3997 5.09615 10.204 5.09615 10C5.09615 9.79599 5.1772 9.60033 5.32146 9.45607C5.46572 9.31181 5.66137 9.23077 5.86538 9.23077H12.2875L9.77212 6.69567C9.70096 6.62389 9.64464 6.53879 9.60638 6.44524C9.56811 6.35169 9.54865 6.25152 9.54909 6.15045C9.54954 6.04937 9.56989 5.94938 9.60898 5.85617C9.64807 5.76296 9.70514 5.67837 9.77692 5.60721C9.84871 5.53606 9.93381 5.47974 10.0274 5.44147C10.1209 5.40321 10.2211 5.38374 10.3222 5.38419C10.5263 5.38509 10.7217 5.46704 10.8654 5.61202L14.6822 9.45817C14.8252 9.60226 14.9054 9.79702 14.9054 10C14.9054 10.203 14.8252 10.3977 14.6822 10.5418L10.8654 14.388C10.7942 14.4599 10.7096 14.517 10.6163 14.5561C10.523 14.5952 10.423 14.6156 10.3218 14.616C10.2207 14.6164 10.1204 14.5968 10.0269 14.5585C9.93327 14.5201 9.84818 14.4636 9.77644 14.3923Z"
          fill="#E97862"
        />
      </svg>
    );
  }
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        id="Vector"
        d="M26 13C26 5.82062 20.1794 0 13 0C5.82062 0 0 5.82062 0 13C0 20.1794 5.82062 26 13 26C20.1794 26 26 20.1794 26 13ZM12.7094 18.71C12.6161 18.6175 12.542 18.5076 12.4913 18.3864C12.4405 18.2653 12.4141 18.1353 12.4136 18.004C12.4131 17.8726 12.4384 17.7425 12.4882 17.6209C12.538 17.4994 12.6112 17.3889 12.7037 17.2956L15.9737 14H7.625C7.35978 14 7.10543 13.8946 6.91789 13.7071C6.73036 13.5196 6.625 13.2652 6.625 13C6.625 12.7348 6.73036 12.4804 6.91789 12.2929C7.10543 12.1054 7.35978 12 7.625 12H15.9737L12.7037 8.70438C12.6112 8.61105 12.538 8.50043 12.4883 8.37881C12.4385 8.2572 12.4132 8.12698 12.4138 7.99558C12.4144 7.86419 12.4409 7.73419 12.4917 7.61302C12.5425 7.49185 12.6167 7.38188 12.71 7.28938C12.8033 7.19688 12.9139 7.12366 13.0356 7.07392C13.1572 7.02417 13.2874 6.99886 13.4188 6.99944C13.6842 7.00062 13.9382 7.10716 14.125 7.29562L19.0869 12.2956C19.2727 12.4829 19.377 12.7361 19.377 13C19.377 13.2639 19.2727 13.5171 19.0869 13.7044L14.125 18.7044C14.0325 18.7978 13.9225 18.8721 13.8012 18.923C13.6799 18.9738 13.5498 19.0003 13.4184 19.0008C13.2869 19.0013 13.1566 18.9759 13.0349 18.926C12.9132 18.8761 12.8026 18.8027 12.7094 18.71Z"
        fill={isHovered ? '#E97862' : '#FFDBD3'}
      />
    </svg>
  );
};

export default ArrowRight;
