import React, { useEffect, useState } from 'react';
import { classNames } from '@pwskills/rachnaui/utils';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { IFaqsDetails } from '@/interfaces/homePage';
import { FAQ_TYPES } from '@/utils/common/common.constants';
import ViewAllButton from '../ViewAllButton';
import FaqsAnswer from './FaqAnswer';
import FaqQuestion from './FaqQuestion';
import FaqMobileView from './FaqMobileView';
import HomepageHeaders from '../HomepageHeader';

type Props = {
  classes?: string;
  wrapperClass?: string;
  faqsList?: IFaqsDetails[];
  showViewAllButton: boolean;
  type?: string;
  accordionWrapperClass?: string;
};

const Faqs = (props: Props) => {
  const {
    classes,
    faqsList,
    showViewAllButton,
    wrapperClass,
    type = FAQ_TYPES.DEFAULT,
    accordionWrapperClass,
  } = props;
  const router = useRouter();
  const [id, setId] = useState<string>('');

  useEffect(() => setId(''), [router]);

  useEffect(() => {
    if (!id && faqsList) {
      setId(faqsList[0]?._id);
    }
  }, [faqsList, id]);

  return (
    <section className={classNames(' bg-white', wrapperClass || '')}>
      <header className="flex items-start justify-between">
        <HomepageHeaders title="Frequently Asked Questions" classes={classes} />
        {showViewAllButton && (
          <Link href="/faqs">
            <ViewAllButton classNames="hidden regular:!inline-flex bg-white text-black shadow-medium hover:bg-white py-3 text-[14px] text-gray-850" />
          </Link>
        )}
      </header>
      {type === FAQ_TYPES.ACCORDION ? (
        <FaqMobileView faqsList={faqsList} accordionWrapperClass={accordionWrapperClass} />
      ) : (
        <div>
          <div className="hidden large:flex items-start justify-between mx-auto gap-x-8">
            <div className="flex flex-col w-[60%] gap-y-4 h-[350px] overflow-auto scrollbar-hide">
              {faqsList &&
                faqsList?.map((faq) => (
                  <FaqQuestion
                    _id={faq._id}
                    question={faq.question}
                    setId={setId}
                    id={id}
                    key={faq._id}
                  />
                ))}
            </div>
            <div className="overflow-hidden w-[40%] whitespace-pre-line">
              <FaqsAnswer id={id} faqsList={faqsList} />
            </div>
          </div>
          <div className="flex large:!hidden">
            <FaqMobileView faqsList={faqsList} />
          </div>
        </div>
      )}
    </section>
  );
};

export default Faqs;
