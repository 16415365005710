import { Button, Container, Typography } from '@pwskills/rachnaui';
import Link from 'next/link';
import React, { useState } from 'react';
import callIcon from '@/assets/icons/courseDescriptionPage/call.svg';
import Image from 'next/image';
import LeadCapture from '../../common/LeadCapture';
import {
  LeadCaptureEnquiryType,
  LeadCaptureType,
  ModalType,
} from '../../../interfaces/leadCapture.enum';

const OnlineDegree = () => {
  const [openModal, setOpenModal] = useState<boolean>(false);

  const handleModalOpen = () => {
    setOpenModal(true);
  };

  return (
    <section className="py-8 large:!py-[154px] bg-[#F8FAFB]">
      <Container>
        <div className="flex flex-col large:!flex-row gap-6 medium:!gap-14 large:!gap-6">
          <div className="w-full large:!w-[737px]">
            <div>
              <Typography
                variant="semi-bold"
                component="body-regular"
                className="font-inter font-bold medium:!font-semibold text-gray-850 text-[16px] medium:!text-[20px]"
              >
                Online Degree
              </Typography>
            </div>
            <div className="flex flex-col gap-6 large:!gap-8 mt-1 medium:!mt-2 large:!mt-6">
              <Typography
                variant="medium"
                component="h1"
                className="font-inter font-bold large:!font-medium text-gray-850 medium:!text-[40px] large:!text-[64px] medium:!leading-[40px] large:!leading-[80px]"
              >
                Where{' '}
                <span className="bg-gradient-to-r from-[#E137A8] from-18% to-[#F38A77] to-100% text-transparent !text-6  bg-clip-text font-bold">
                  Learning{' '}
                </span>
                Meets The Expectation
              </Typography>

              <Typography
                variant="medium"
                component="body-regular"
                className="font-inter font-normal medium:!font-medium text-gray-650 text-[16px] medium:!text-[20px] leading-[24px] medium:!leading-[30px] w-full large:!w-[70%] text-center medium:!text-left"
              >
                At PW Skills, we are Revolutionising your journey of stepping your first foot into
                your Career through our college programs with partnering up with Top Tier Colleges
              </Typography>

              <div className="flex gap-[10px] medium:!gap-6 justify-center large:!justify-normal">
                <div>
                  <Link
                    href="/online-degree/?position=online_degree_section&buttonText=explore_courses"
                    className="self-start w-full"
                  >
                    <Button
                      variant="primary"
                      className="mx-auto large:!mx-0 w-full medium:!w-fit font-bold px-4 medium:!px-6"
                    >
                      Explore Courses
                    </Button>
                  </Link>
                </div>

                <div>
                  <Button
                    variant="secondary"
                    className="mx-auto large:!mx-0 w-full medium:!w-fit font-bold px-4 medium:!px-5 !gap-3"
                    onClick={handleModalOpen}
                  >
                    Talk to counsellor
                    <Image src={callIcon} alt="callIcon" width={20} height={20} />
                    {/* <img src={image} alt="about-us" className="w-[20px]" /> */}
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <img
            src="/images/homePage/homepage-degree.webp"
            alt="online-degree-homepage-img"
            className="!w-full !h-full medium:!w-[512px] large:!w-[520px] m-auto"
          />
        </div>

        {openModal && (
          <LeadCapture
            leadCaptureType={LeadCaptureType.MODAL}
            modalType={ModalType.ONLINE_DEGREE}
            modelOpen={openModal}
            setModelOpen={setOpenModal}
            buttonText="Get Connected"
            img="/images/bhilai-lead-capture-modal-pic.svg"
            msgLineOne="We Got Your Response."
            msgLineTwo="Our Counsellor will reach out soon"
            type="ConnectWithCounsellor"
            enquiryType={LeadCaptureEnquiryType.ONLINE_DEGREE_SKILLSHOMEPAGE}
            enquiryTypeNPF="Online Degree_skillshomepage"
            pageName="home_page"
          />
        )}
      </Container>
    </section>
  );
};

export default OnlineDegree;
