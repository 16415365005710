/* eslint-disable @typescript-eslint/indent */
import React, { useEffect, useState } from 'react';
import { Button, Container } from '@pwskills/rachnaui';
import { dataLayer } from '@/utils/common/dataLayer.utils';
import CategorySection from './CategorySection';
import HomepageHeaders from '../../common/HomepageHeader';
import { ICategoryDetails } from '../../../interfaces/homePage';

const OurCourses = ({
  categoriesDetails,
  loading,
  handleShowMoreButtonClick,
}: {
  categoriesDetails: ICategoryDetails[];
  loading: boolean;
  handleShowMoreButtonClick: (param1: boolean) => void;
}) => {
  const [showAllCategories, setShowAllCategories] = useState<boolean>(false);
  const [categoriesList, setCategoriesList] = useState<ICategoryDetails[]>([]);

  const toggleCategoriesDisplay = () => {
    setShowAllCategories(!showAllCategories);
    handleShowMoreButtonClick(!showAllCategories);
  };

  useEffect(() => {
    const categoryWithCourses = categoriesDetails.filter((category) => {
      return category.courseDetails && category.courseDetails.length > 0;
    });
    if (categoryWithCourses) setCategoriesList(categoryWithCourses);
  }, [categoriesDetails]);

  useEffect(() => {
    dataLayer({
      page_name: 'home',
      event: 'home',
    });
  }, []);

  return (
    <section className="py-8 large:!py-14 bg-[#F8FAFB]">
      <Container>
        <header className="flex items-start justify-between">
          <HomepageHeaders title="Our Courses" />
        </header>
        <div>
          {categoriesList.slice(0, showAllCategories ? categoriesList.length : 3).map((item) => (
            <CategorySection
              categoryCourses={item?.courseDetails}
              isLoading={loading}
              title={item?.categoryDetails?.title}
              slug={item?.categoryDetails?.slug}
              categoryId={item?.categoryDetails?._id}
            />
          ))}

          {categoriesList.length > 3 && (
            <div className="flex justify-center mt-6">
              {/* this link should be changed */}
              <Button
                variant="primary"
                size="small"
                className="whitespace-nowrap !mb-6 inline-flex shadow-medium text-white rounded-3xl !px-5 !py-3"
                style={{ backgroundColor: '#414347' }}
                onClick={toggleCategoriesDisplay}
              >
                {showAllCategories ? 'Show less categories' : 'Show all categories'}
              </Button>
            </div>
          )}
        </div>
      </Container>
    </section>
  );
};

export default OurCourses;
