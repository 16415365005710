import { Button } from '@pwskills/rachnaui';
import { ArrowForward } from '@pwskills/rachnaui/Icons';
import React from 'react';

type Props = {
  classNames?: string;
};

const ViewAllButton = ({ classNames }: Props) => {
  return (
    <Button
      variant="primary"
      size="small"
      endIcon={<ArrowForward className="w-4 h-4" />}
      className={`${classNames} whitespace-nowrap !mb-6`}
    >
      View all
    </Button>
  );
};

export default ViewAllButton;
