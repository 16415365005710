import { Typography } from '@pwskills/rachnaui';
import { ArrowForwardCircleOutline } from '@pwskills/rachnaui/Icons';
import { classNames } from '@pwskills/rachnaui/utils';
import React from 'react';

type Props = {
  _id: string;
  question?: string;
  setId: React.Dispatch<React.SetStateAction<string>>;
  id: string;
};

const FaqQuestion = ({ _id, question, setId, id }: Props) => {
  return (
    <button
      type="button"
      className="flex items-center justify-between w-full border-b border-stroke-200 pb-4 cursor-pointer"
      onClick={() => {
        setId(_id);
      }}
    >
      <Typography
        component="h4"
        variant={_id === id ? 'semi-bold' : 'regular'}
        className={classNames(_id === id ? 'text-gray-800' : 'text-gray-650', 'text-left')}
      >
        {question}
      </Typography>
      <ArrowForwardCircleOutline
        className={classNames('w-6 h-6 shrink-0', _id === id ? 'text-gray-800' : 'text-gray-600')}
      />
    </button>
  );
};

export default FaqQuestion;
