import React from 'react';
import Lottie from 'react-lottie';
import { Container } from '@pwskills/rachnaui';
import { ourProducts } from '@/utils/homePage/home.constants';
import { dataLayer } from '@/utils/common/dataLayer.utils';
import productRightImage from '../../../assets/Lottie/products-right.json';
import ProductCard from './ProductCard';
import HomepageHeaders from '../../common/HomepageHeader';

const OurProducts = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: productRightImage,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <section className="py-8 large:!py-14 bg-white" id="product">
      <Container>
        <HomepageHeaders
          title="Explore Our Ecosystem "
          subtitle="A one-stop destination for all your learning to placement needs"
        />
        <div className="flex flex-col large:!flex-row items-center justify-between gap-x-6 xlarge:!gap-x-20 pt-10">
          <div className="flex flex-col regular:!flex-row items-center justify-center  regular:!justify-start gap-x-3 regular:!flex-wrap large:!flex-nowrap w-full large:w-[60%] xlarge:w-1/2">
            <div className="flex flex-col items-center justify-center gap-y-6 large:!gap-y-3">
              {ourProducts.slice(0, 3)?.map((product) => (
                <ProductCard
                  product={product}
                  key={product.id}
                  onClick={(value) =>
                    dataLayer({ explore_ecosystem: value, page_name: 'home', event: 'home' })
                  }
                />
              ))}
            </div>
            <div className="flex flex-col items-center justify-center gap-y-6 large:!gap-y-3 pt-8 large:pt-0">
              {ourProducts.slice(3, 5)?.map((product) => (
                <ProductCard
                  product={product}
                  key={product.id}
                  onClick={(value) =>
                    dataLayer({
                      explore_ecosystem: value,
                      page_name: 'home',
                      event: 'home',
                    })
                  }
                />
              ))}
            </div>
          </div>
          <figure className="hidden large:!block w-[45%] xlarge:!w-1/2">
            <Lottie options={defaultOptions} height={450} width={480} />
          </figure>
        </div>
      </Container>
    </section>
  );
};

export default OurProducts;
