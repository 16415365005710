import { Typography } from '@pwskills/rachnaui';
import { companyImage } from '@/utils/common/env.constants';
import React from 'react';
import { ISuccessStory } from '../../../interfaces/homePage';

const SuccessStoryCard = ({ story }: { story: ISuccessStory }) => {
  return (
    <div
      className="!w-[93%] medium:!w-[302px] !m-0 bg-white rounded-b-[8px] rounded-t-[8px] h-auto shadow-light shrink-0 flex flex-col p-0 pb-7 justify-between border border-stroke-200"
      key={story._id}
    >
      <div>
        <Typography
          component="body-regular"
          variant="bold"
          className="self-start bg-green-50 w-full text-center text-green-600 py-[10px] rounded-t-[8px]"
        >
          {story.achievementStats}
        </Typography>
        <div className="px-8 pt-7 flex items-center justify-start gap-x-4">
          <figure>
            <img
              src={story.studentImage}
              alt={story.studentName}
              className="w-11 h-11 rounded-full"
            />
          </figure>
          <div>
            <Typography variant="bold" component="body-regular" className="text-gray-900">
              {story.studentName}
            </Typography>
            <Typography
              variant="semi-bold"
              component="body-small"
              className="text-gray-650 h-[32px]"
            >
              {story.studentDesignation}
            </Typography>
          </div>
        </div>
        <Typography
          component="body-small"
          variant="light"
          className="px-8  pt-3 !text-gray-650 line-clamp-5 text-left"
        >
          {story.comment}
        </Typography>
      </div>
      <div className="justify-self-end pt-4 flex items-center justify-around px-8  w-full self-end">
        <div className="flex flex-col items-start justify-center w-[40%]">
          <Typography variant="bold" component="body-small" className="text-gray-850">
            From
          </Typography>
          {story.isFresher ? (
            <Typography variant="bold" component="body-regular" className="text-[#C84C25]">
              Fresher
            </Typography>
          ) : story.fromCompanyDetails && story.fromCompanyDetails[0]?.companyLogoFileName ? (
            <figure>
              <img
                src={`${companyImage}/company_logos/${story.fromCompanyDetails[0]?.companyLogoFileName}`}
                alt="company"
                className="w-20 aspect-[5/2] object-contain"
              />
            </figure>
          ) : (
            ''
          )}
        </div>
        <figure className="mt-4 w-[20%]">
          <img src="/images/homePage/dash-arrow.svg" alt="icon" />
        </figure>
        <div className="flex flex-col items-start justify-center w-[40%]">
          <Typography variant="bold" component="body-small" className="text-gray-850">
            To
          </Typography>
          {story.toCompanyDetails && story.toCompanyDetails[0]?.companyLogoFileName ? (
            <figure>
              <img
                src={`${companyImage}/company_logos/${story.toCompanyDetails[0]?.companyLogoFileName}`}
                alt="company"
                className="w-20 aspect-[5/2] object-contain"
              />
            </figure>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
};

export default SuccessStoryCard;
