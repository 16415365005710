import Slider from '@pwskills/rachnaui/components/Slider';
import { Container } from '@pwskills/rachnaui';
import { ICompanyDetails } from '@/interfaces/homePage';
import CompanyCard from './CompanyCard';
import HomepageHeaders from '../../common/HomepageHeader';

const OurAchievers = ({ companyLogos }: { companyLogos: ICompanyDetails[] }) => {
  return (
    <section className="py-8 large:!pt-24">
      <Container>
        <header className="mb-6">
          <HomepageHeaders title="Our Achievers Work With" />
        </header>
        <Slider className="regular:gap-x-4 [&>div]:m-2" iconWrapperClass="large:!mt-8" autoPlay>
          {companyLogos &&
            companyLogos?.map((company) => <CompanyCard {...company} key={company._id} />)}
        </Slider>
      </Container>
    </section>
  );
};

export default OurAchievers;
