import { DOMAIN } from './homePage/env.constants.utils';

const RouteName = {
  index: DOMAIN,
  aboutUs: `${DOMAIN}/about-us`,
  courses: `${DOMAIN}/courses`,
  category: `${DOMAIN}/category`,
  campusEdge: `${DOMAIN}/campus-edge`,
  course: `${DOMAIN}/course`,
  termsAndConditions: `${DOMAIN}/terms-and-conditions`,
  privacyPolicy: `${DOMAIN}/privacy-policy`,
  jobAssistance: `${DOMAIN}/job-assistance`,
  faqs: `${DOMAIN}/faqs`,
  contactUs: `${DOMAIN}/contact-us`,
  masterclass: `${DOMAIN}/masterclass`,
};

// Function to get the canonical URL based on the provided value
export const getCanonicalUrl = (courseId: string) => {
  // Define your mapping or conditions
  const canonicalMap: { [key: string]: string } = {
    // production course ids for which canonical url is added
    // dsa c++
    '63eb1ae1194b22195fe5d967': `${DOMAIN}/course/dsa-cpp/`, // https://pwskills.com/course/decode-dsa-with-c/
    '648190746977cc4a9bdfaeab': `${DOMAIN}/course/dsa-cpp/`, // https://pwskills.com/course/decode-dsa-with-c-2.0/
    '63a0ba6180762892138f404f': `${DOMAIN}/course/dsa-cpp/`, // https://pwskills.com/course/c-foundation/
    '642d617aaf09d6d7b2577870': `${DOMAIN}/course/dsa-cpp/`, // https://pwskills.com/course/c-programming-foundation

    // full stack web development course
    '6641dd452fb0a240b49c6c56': `${DOMAIN}/course/best-full-stack-web-development/`, // Full Stack Development Course - Hinglish
    '63a2ecdd88994300d47eb9ad': `${DOMAIN}/course/best-full-stack-web-development/`, // https://pwskills.com/course/full-stack-web-development/
    '64a514966977cc6e87e27504': `${DOMAIN}/course/best-full-stack-web-development/`, // https://pwskills.com/course/full-stack-web-development-ja/
    '63a2ecf58899439c8d7ebdc6': `${DOMAIN}/course/best-full-stack-web-development/`, // https://pwskills.com/course/full-stack-web-development-1-hindi/
    '642152e5af09d60df056811a': `${DOMAIN}/course/best-full-stack-web-development/`, // https://pwskills.com/course/full-stack-web-development-20-hindi/
    '64215310af09d6aa495682f5': `${DOMAIN}/course/best-full-stack-web-development/`, // https://pwskills.com/course/Full-Stack-Web-Development-2-english/
    '64af76666977cc11e7e4086e': `${DOMAIN}/course/best-full-stack-web-development/`, // https://pwskills.com/course/web-development
    '6448ca86508654220671ad2d': `${DOMAIN}/course/best-full-stack-web-development/`, // https://pwskills.com/course/masterclass-understanding-apis-in-web-development-with-roadmap/  ++ADDED
    '64c24648228e13038cd7f1bd': `${DOMAIN}/course/best-full-stack-web-development/`, // https://pwskills.com/course/wed-dev-101

    // Data Analytics Course
    '6641e54b9e610d0f237ff372': `${DOMAIN}/course/data-analytics/`, // Data Analytics Course - Hinglish
    '64c787ac228e13bbe7db67ee': `${DOMAIN}/course/data-analytics/`, // https://pwskills.com/course/mastering-full-stack-data-analytics/
    '63a0bfb680762882188f490b': `${DOMAIN}/course/data-analytics/`, // https://pwskills.com/course/business-analytics-foundations/

    // decode-javadsa
    '63a0ad31ff94e14fb832b423': `${DOMAIN}/course/decode-javadsa/`, // https://pwskills.com/course/cracking-the-coding-interview-in-java-foundation
    '642d6c3faf09d67ebf57789d': `${DOMAIN}/course/decode-javadsa/`, // https://pwskills.com/course/javascript-foundations
    '6458eb8a6977cc6cd6dd583c': `${DOMAIN}/course/decode-javadsa/`, // https://pwskills.com/course/masterclass-java-spring-and-springboot
    '63a2f02d889943137f7ec85f': `${DOMAIN}/course/decode-javadsa/`, // https://pwskills.com/course/java-with-dsa-and-system-design-1-hindi
    '642165fdaf09d675ce568cd0': `${DOMAIN}/course/decode-javadsa/`, // https://pwskills.com/course/java-with-dsa-and-system-design-2-english

    // master-generative-ai-data-science-course
    '6641b8422fb0a2b78a9c4c74': `${DOMAIN}/course/master-generative-ai-data-science-course/`, // Data Science With Generative AI Course - Hinglish
    '64b4de616977ccb0f3e45753': `${DOMAIN}/course/master-generative-ai-data-science-course/`, // https://pwskills.com/course/statistics-for-data-science-english
    '63a2ea7e8899438ee77eae5a': `${DOMAIN}/course/master-generative-ai-data-science-course/`, // https://pwskills.com/course/data-science-masters-hindi
    '64207e5daf09d6280f5654d0': `${DOMAIN}/course/master-generative-ai-data-science-course/`, // https://pwskills.com/course/data-science-masters-2-hindi
    '64a264076977ccc0a0e20c13': `${DOMAIN}/course/master-generative-ai-data-science-course/`, // https://pwskills.com/course/full-stack-data-science-pro
    '61e9b66e47eea35cecf3f7b9': `${DOMAIN}/course/master-generative-ai-data-science-course/`, // https://pwskills.com/course/data-science-masters-hindi
    '61b9f70370ffc3634c646fb3': `${DOMAIN}/course/master-generative-ai-data-science-course/`, // https://pwskills.com/course/full-stack-data-science-pro
    '6426be96af09d653f55710cd': `${DOMAIN}/course/master-generative-ai-data-science-course/`, // https://pwskills.com/course/python-foundations
    '64b4cfdd6977cc3795e4504b': `${DOMAIN}/course/master-generative-ai-data-science-course/`, // https://pwskills.com/course/python-for-beginners

    // return empty sting if the ids are not present
    default: '',
  };

  // check if the canonical map object has the course ID
  const foundKey = Object.keys(canonicalMap).find((key) => key === courseId);
  if (foundKey) {
    return canonicalMap[foundKey];
  }
  return canonicalMap.default;
};

export default RouteName;
