const LoadingCategoryCardSkeleton = ({ length }: { length: number }) => {
  return (
    <div
      // eslint-disable-next-line react/no-array-index-key
      key={length}
      className="flex w-[100%] medium:!w-[332px] large:!w-[31%] items-center justify-start gap-x-4 !m-0 large:!self-start !bg-[#F8FAFB] !p-0 !shadow-light hover:large:shadow-medium cursor-pointer"
    >
      <div className="flex flex-col w-full h-full justify-between  animate-pulse">
        <div className="flex flex-row !px-3 !py-2 large:!pt-5 large:!pr-5 large:!pl-4 large:!pb-4 gap-[20px] large:gap-[18px] justify-between items-center  animate-pulse">
          <div className="gap-[6px] flex flex-col  w-full  animate-pulse">
            <div className="bg-gray-400 rounded p-2 w-full h-[30px]" />
            <div className="bg-gray-400 rounded p-2 w-full h-[22px]" />
            <div className="bg-gray-400 rounded p-2 w-full h-[22px]" />
          </div>

          <div className="flex items-center !w-[56px] !h-[56px]  !min-w-[56px] !min-h-[56px] large:!w-[72px] large:!h-[72px] large:!min-w-[72px] large:!min-h-[72px]">
            <div className="bg-gray-400 rounded h-full w-full  animate-pulse" />
          </div>
        </div>

        <div className="flex items-center justify-between bg-white !px-4 !py-[13px] large:!py-[17px]   animate-pulse">
          <div className="bg-gray-400 rounded p-2 w-[100px]  animate-pulse" />

          <div className="cursor-pointer w-5 h-5  animate-pulse">
            <div className="bg-gray-400 rounded p-2 w-full  animate-pulse" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadingCategoryCardSkeleton;
