import { Slider } from '@pwskills/rachnaui';
import { ISuccessStory } from '@/interfaces/homePage';
import SuccessStoryCard from './SuccessStoryCard';

const SuccessStoryCards = ({ successStories }: { successStories: ISuccessStory[] }) => {
  return (
    <Slider
      iconWrapperClass="!p-0 !mx-0"
      wrapperClass="flex flex-col gap-6"
      className="gap-4 medium:gap-6 "
      positionOfSliderInMobile="bottom"
    >
      {successStories?.map((story) => (
        <SuccessStoryCard story={story} key={story._id} />
      ))}
    </Slider>
  );
};

export default SuccessStoryCards;
