import { Button } from '@pwskills/rachnaui';
import { ArrowForward } from '@pwskills/rachnaui/Icons';
import React from 'react';
import { dataLayer } from '../../../utils/common/dataLayer.utils';

type Props = {
  classNames?: string;
  clickText?: string;
  section?: string;
};

const ViewAllButton = ({ classNames, clickText, section: sectionProp }: Props) => {
  const sendDataLayerEvent = (type?: string, section?: string) => {
    dataLayer({
      event: 'clicks',
      click_text: type,
      section,
    });
  };

  return (
    <Button
      variant="primary"
      size="small"
      endIcon={<ArrowForward className="w-4 h-4" />}
      className={classNames}
      onClick={() => sendDataLayerEvent(clickText, sectionProp)}
    >
      View All
    </Button>
  );
};

export default ViewAllButton;
