import React from 'react';

const LoadingFeatureCardSkeleton = ({ length = 3 }: { length?: number }) => {
  return (
    <div
      key={`i${length + 1}`}
      className="flex mt-5 relative !w-full medium:!w-[332px] large:!w-[411px] !mx-3 large:!mx-6 rounded-b-[20px] rounded-t-[20px] !shadow-light !shrink-0 flex-col hover:large:shadow-medium bg-white !p-0 !m-0"
    >
      <div className="bg-gray-400 rounded  w-full animate-pulse h-[200px]" />
      <div className="flex flex-col gap-4 animate-pulse p-4">
        <div className="flex flex-col gap-1 animate-pulse">
          <div className="bg-gray-400 rounded h-6 p-2 w-full animate-pulse" />
          <div className="bg-gray-400 rounded p-2 h-6  w-full animate-pulse" />
          <div className="bg-gray-400 rounded p-2 h-6  w-full animate-pulse" />
          <div className="bg-gray-400 rounded p-2 h-6  w-full animate-pulse" />
        </div>
        <div className="bg-gray-400 rounded p-2 h-6  w-full animate-pulse" />
        <div className="flex gap-1 animate-pulse h-12">
          <div className="bg-gray-400 rounded p-2 w-full animate-pulse" />
          <div className="bg-gray-400 rounded p-2 w-full animate-pulse" />
        </div>
      </div>
    </div>
  );
};

export default LoadingFeatureCardSkeleton;
