import { Card } from '@pwskills/rachnaui';
import React from 'react';
import { companyImage } from '@/utils/common/env.constants';
import { ICompanyDetails } from '@/interfaces/homePage';

const CompanyCard = (company: ICompanyDetails) => {
  const { name, companyLogoFileName } = company;
  return (
    <Card
      className="p-[6px] bg-white shadow-light rounded-2xl shrink-0 !w-[286px] regular:!w-[330px] large:!w-[18.5%] !mx-3 regular:!mx-6"
      key={companyLogoFileName}
    >
      <figure className="flex items-center justify-center">
        <img
          src={`${companyImage}/company_logos/${companyLogoFileName}`}
          alt={name}
          className="w-[60%] regular:!w-[70%] aspect-[4/2] object-contain mx-auto"
        />
      </figure>
    </Card>
  );
};

export default CompanyCard;
