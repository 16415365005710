/* eslint-disable @typescript-eslint/indent */
import { domainUrl, s3Url } from '@/utils/common/env.constants';
import { Container, Slider } from '@pwskills/rachnaui';
import React, { useContext, useEffect, useState } from 'react';
import { useScreenSize } from '@pwskills/rachnaui/hooks';
import UserDetailsContext from '@/context/UserDetailsContext';
import { IWebBanner } from '@/interfaces/homePage';
import HomePage from '@/api/homePage';
import moEvent from '@/utils/moEngage';
import { dataLayer } from '../../../utils/common/dataLayer.utils';

const Banner = () => {
  const { width } = useScreenSize();

  const [data, setData] = useState<IWebBanner[] | []>([]);
  const { user, isLoggedIn } = useContext(UserDetailsContext);

  const fetchWebBanner = async () => {
    try {
      const response = await HomePage.getWebBanner();
      if (response) {
        const webBanners = response?.data?.filter((item) => item?.bannerType === 'WEB_BANNER');
        setData(webBanners);
      }
    } catch (error: unknown) {
      // eslint-disable-next-line no-console
      // console.error((error as IError)?.error);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      fetchWebBanner();
    }
  }, []);

  const handleBannerClick = (banner: IWebBanner) => {
    if (user?._id && isLoggedIn) {
      const moEventData: { [key: string]: any } = {
        user_id: user?._id || '',
        phone_number: user?.phoneNumber || '',
        user_type: isLoggedIn ? 'loggedin' : 'not_loggedin',
        banner_identifier: banner?._id,
        banner_message: '',
        banner_link: banner?.link,
      };
      if (user?.verified && user?.email) moEventData.email = user.email;
      moEvent('banner_click', moEventData);
    }
  };

  const sendDataLayerEvent = (banner: IWebBanner) => {
    const dataLayerObj = {
      event: 'homepage_banner_click',
      banner_id: banner?._id,
      banner_url: banner?.link,
      banner_type: 'homepage_banner',
    };
    dataLayer(dataLayerObj);
  };

  const getURL = (link: string) => {
    if (link === `${domainUrl}`) {
      return `${link}/?position=banner_click`;
    }
    if (link === `${domainUrl}/courses`) {
      return `${link}/?source=banner_click`;
    }
    if (link.startsWith(`${domainUrl}/course/`)) {
      return `${link}/?source_position=banner_click`;
    }
    if (link.includes(`${domainUrl}/checkout`)) {
      return `${link}/&source=banner_click`;
    }
    if (link.startsWith(`${domainUrl}/category/`)) {
      return `${link}/?position=banner_click&source=${domainUrl}`;
    }
    if (link === `${domainUrl}/online-degree`) {
      return `${link}/?position=banner`;
    }
    return link;
  };

  if (data.length === 0) return null;

  return (
    <Container>
      {data?.length > 0 && (
        <Slider className="py-8" autoPlay>
          {data?.map((banner) => {
            const link = getURL(banner?.link);
            return (
              <div
                className="mt-5 relative !w-full !mx-3 large:!mx-6 !shrink-0 flex-col"
                key={banner?._id}
              >
                <a
                  onClick={() => {
                    handleBannerClick(banner);
                    sendDataLayerEvent(banner);
                  }}
                  href={link}
                  rel="nofollow"
                  style={{ pointerEvents: !banner?.link ? 'none' : 'inherit' }}
                >
                  <div className="w-full h-full">
                    {width < 1200 ? (
                      <img
                        width={500}
                        height={700}
                        src={`${s3Url}/assets/uploads/banners/${banner.mobileImgUrl}`}
                        alt="banner"
                      />
                    ) : (
                      <img
                        width={1700}
                        height={700}
                        src={`${s3Url}/assets/uploads/banners/${banner.webImgUrl}`}
                        alt="banner"
                      />
                    )}
                  </div>
                </a>
              </div>
            );
          })}
        </Slider>
      )}
    </Container>
  );
};

export default Banner;
