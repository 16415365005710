import { Typography } from '@pwskills/rachnaui';
import { classNames } from '@pwskills/rachnaui/utils';
import React from 'react';

type Props = {
  classes?: string;
  title: string;
  subtitle?: string;
};

const HomepageHeaders = (props: Props) => {
  const { classes, title, subtitle } = props;

  return (
    <>
      <Typography
        component="h2"
        variant="bold"
        className={classNames(
          'text-left font-inter text-gray-900 !w-full text-[24px] medium:!text-[32px]',
          subtitle ? 'pb-0' : 'pb-0',
          classes || ' '
        )}
      >
        {title}
      </Typography>
      {subtitle ? (
        <Typography
          variant="light"
          component="body-regular"
          className="text-[#1B2124] text-left mt-2"
        >
          {subtitle}
        </Typography>
      ) : null}
    </>
  );
};

export default HomepageHeaders;
