import { Accordion, Typography } from '@pwskills/rachnaui';
import React from 'react';
import { IFaqsDetails } from '@/interfaces/homePage';

type Props = {
  faqsList?: IFaqsDetails[];
  accordionWrapperClass?: string;
};

const FaqMobileView = ({ faqsList, accordionWrapperClass }: Props) => {
  return (
    <div className="w-full ">
      {faqsList?.map((faq, idx) => (
        <Accordion
          defaultState={idx === 0}
          title={faq.question}
          className="!w-full mx-auto regular:!mx-1 "
          titleClass="font-semibold"
          key={faq._id}
          wrapperClass={accordionWrapperClass ?? ''}
        >
          {faq?.videoLink && faq?.videoLink.trim() !== '' && (
            <iframe
              src={`${faq.videoLink}`}
              title={`faq-mobile-video-${idx}`}
              className="rounded-lg w-full mx-auto h-80"
              loading="lazy"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          )}
          <Typography
            component="body-regular"
            variant="light"
            className="block pt-2 w-full mx-auto whitespace-pre-line"
          >
            {faq?.answer}
          </Typography>
        </Accordion>
      ))}
    </div>
  );
};

export default FaqMobileView;
