/* eslint-disable @typescript-eslint/indent */
import React from 'react';
import { Container } from '@pwskills/rachnaui';
import Link from 'next/link';
import { environment } from '@/utils/common/env.constants';
import SuccessStoryCards from '@/components/common/SuccessStoryCards';
import ViewAllButton from '../../common/ViewAllButton';
import HomepageHeaders from '../../common/HomepageHeader';
import { ISuccessStory } from '../../../interfaces/homePage';

const SuccessStories = ({ successStories }: { successStories: ISuccessStory[] }) => {
  const renderLink =
    environment === 'dev'
      ? 'https://halloffame-dev.pwskills.com'
      : environment === 'staging'
      ? 'https://halloffame-staging.pwskills.com'
      : 'https://halloffame.pwskills.com';

  return (
    <section className="py-8 large:!py-14">
      <Container>
        <div className="flex flex-col">
          <header className="flex items-start justify-between mb-6 medium:!mb-0">
            <HomepageHeaders title="Success Stories" />
            <Link href={renderLink}>
              <ViewAllButton classNames="hidden regular:!inline-flex bg-white text-black shadow-medium hover:bg-white py-3 text-gray-850" />
            </Link>
          </header>
          {successStories && <SuccessStoryCards successStories={successStories} />}
          <Link
            href={{
              pathname: `${renderLink}`,
              query: {
                source: window.location.href,
                position: 'viewmore',
              },
            }}
            className="w-full text-center"
            passHref
          >
            <ViewAllButton classNames="inline-flex regular:!hidden bg-white shadow-medium hover:bg-white py-3 mt-8 text-gray-850" />
          </Link>
        </div>
      </Container>
    </section>
  );
};

export default SuccessStories;
