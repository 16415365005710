import CourseDescription from '@/api/courseDescription';
import { domainUrl } from '@/utils/common/env.constants';
import { useRouter } from 'next/router';

export default function useRedirectToCategory() {
  const router = useRouter();
  const goToCategoryPage = async (slug: string, params: string) => {
    try {
      const response: { data: string } = await CourseDescription.courseRedirectionURL(
        `${domainUrl}/category/${slug}`,
        params
      );
      if (Object.keys(response?.data).length > 0) {
        window.open(response?.data, '_self');
      } else router.push(`/category/${slug}/${params}`);
    } catch (error) {
      router.push(`/category/${slug}/${params}`);
    }
  };
  return {
    goToCategoryPage,
  };
}
