import React from 'react';
import { Container, Slider, Typography } from '@pwskills/rachnaui';
import { randomId } from '@pwskills/rachnaui/utils';
import LoadingCategoryCardSkeleton from '@/components/common/LoadingSkeleton/LoadingCategoryCardSkeleton';
import GroupImg from '@/assets/icons/categoryPage/Group.svg';
import Image from 'next/image';
import CategoryCard from './CategoryCard';
import HomepageHeaders from '../../common/HomepageHeader';
import { IExplore } from '../../../interfaces/homePage';

const OurCategories = ({
  categories,
  isLoading,
}: {
  categories: IExplore[];
  isLoading: boolean;
}) => {
  const chunkValueForMobile = 2;
  const chunkValueForTabAndDesktop = 6;

  // to show 2 category cards in mobile ui at a time
  const chunkedCategoriesForMobile: IExplore[][] = [];
  for (let i = 0; i < categories.length; i += chunkValueForMobile) {
    chunkedCategoriesForMobile.push(categories.slice(i, i + chunkValueForMobile));
  }

  // to show 6 category cards in tab and desktop ui at a time
  const chunkedCategoriesForTabAndDesktop: IExplore[][] = [];
  for (let i = 0; i < categories.length; i += chunkValueForTabAndDesktop) {
    chunkedCategoriesForTabAndDesktop.push(categories.slice(i, i + chunkValueForTabAndDesktop));
  }

  return (
    <section className="py-8 large:!py-14 bg-white relative" id="product">
      <Container>
        {/* <Headers title="Explore Our Categories" classes="text-gray-850 !text-start" /> */}
        <HomepageHeaders title="Explore Our Categories" classes="text-gray-850" />
        <div className="mt-2 medium:mt-3">
          <Typography variant="regular" component="body-regular" className="text-gray-850">
            Discover Your Passion
          </Typography>
        </div>

        <div className="block medium:!hidden">
          {/* Render Slider component  on mobile */}
          {isLoading ? (
            <div className="mt-5 relative !w-[100%] !shrink-0 bg-white">
              <div className="flex flex-wrap gap-6 ">
                {Array(6)
                  .fill(randomId())
                  .map(() => (
                    <LoadingCategoryCardSkeleton length={6} />
                  ))}
              </div>
            </div>
          ) : chunkedCategoriesForMobile.length > 1 ? (
            <Slider positionOfSliderInMobile="bottom" wrapperClass="flex flex-col gap-4">
              {chunkedCategoriesForMobile.map((chunk) => (
                <div className="mt-5 relative !w-[100%] !shrink-0 bg-white">
                  <div className="flex flex-col gap-6 ">
                    {chunk.map((category) => (
                      <CategoryCard {...category} key={category._id} />
                    ))}
                  </div>
                </div>
              ))}
            </Slider>
          ) : (
            chunkedCategoriesForMobile.map((chunk) => (
              <div className="mt-5 relative !w-[100%] !shrink-0 bg-white">
                <div className="flex flex-col gap-6">
                  {chunk.map((category) => (
                    <CategoryCard {...category} key={category._id} />
                  ))}
                </div>
              </div>
            ))
          )}
        </div>
        <div className="hidden medium:!flex">
          {/* Render Slider component  on mobile */}
          {isLoading ? (
            <div className="mt-5 relative !w-[100%] !shrink-0 bg-white">
              <div className="flex flex-wrap gap-6 ">
                {Array(6)
                  .fill(randomId())
                  .map(() => (
                    <LoadingCategoryCardSkeleton length={6} />
                  ))}
              </div>
            </div>
          ) : chunkedCategoriesForTabAndDesktop.length > 1 ? (
            <Slider positionOfSliderInMobile="bottom" wrapperClass="flex flex-col gap-4 ">
              {chunkedCategoriesForTabAndDesktop.map((chunk) => (
                <div className="mt-5 relative !w-[100%] !shrink-0 bg-white">
                  <div className="flex flex-wrap gap-6 ">
                    {chunk.map((category) => (
                      <CategoryCard {...category} key={category._id} />
                    ))}
                  </div>
                </div>
              ))}
            </Slider>
          ) : (
            chunkedCategoriesForTabAndDesktop.map((chunk) => (
              <div className="mt-5 relative !w-[100%] !shrink-0 bg-white">
                <div className="flex flex-wrap gap-6 ">
                  {chunk.map((category) => (
                    <CategoryCard {...category} key={category._id} />
                  ))}
                </div>
              </div>
            ))
          )}
        </div>
        <Image
          src={GroupImg}
          alt="illustration img"
          className="absolute right-0 bottom-0 hidden medium:!block"
        />
      </Container>
    </section>
  );
};
export default OurCategories;
